import { LP_GLOBAL_WINNERS } from 'lib/@getethos/experiments/constants'
import { useDecisionForFlag } from 'lib/FeaturesAndExperiments/getDecisionForFlag'

export const useLpGlobalWinner = () => {
  const { CONTROL, TREATMENT_1, TREATMENT_2, TREATMENT_3, TREATMENT_4 } =
    LP_GLOBAL_WINNERS.VARIATIONS
  const { isEnabled, isLoading, variation } = useDecisionForFlag({
    name: LP_GLOBAL_WINNERS.EXPERIMENT_KEY,
    fallbackVariation: CONTROL,
  })

  const isControl = variation === CONTROL
  const isTreatment1 = isEnabled && variation === TREATMENT_1
  const isTreatment2 = isEnabled && variation === TREATMENT_2
  const isTreatment3 = isEnabled && variation === TREATMENT_3
  const isTreatment4 = isEnabled && variation === TREATMENT_4

  return {
    isControl,
    isLoading,
    isTreatment1,
    isTreatment2,
    isTreatment3,
    isTreatment4,
  }
}
